import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
// import BottomNavigation from '@material-ui/core/BottomNavigation';

const Copyright = () => {
    return (
        <Typography variant="body2" color="textPrimary">
            {'Copyright © '}
                Mari Metsola {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 2),
        marginTop: 'auto',
        background: `linear-gradient(90deg, ${theme.palette.primary.dark} 1%, ${theme.palette.secondary.dark}, ${theme.palette.primary.dark} 99%)`,
        textAlign: "center",
    },
}));

const Footer = () => {
    const classes = useStyles();

    return (
        // <BottomNavigation
        //     className={classes.root}
        // >
        //     <Copyright />
        // </BottomNavigation>
        <footer className={classes.root}>
            <Container maxWidth="sm">
                <Copyright />
            </Container>
        </footer>
    );
};

export default Footer;