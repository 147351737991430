import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Home from './Home';
import Projects from './Projects';
import About from './About';
import Contact from './Contact';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        // background: `radial-gradient(${theme.palette.primary.dark} 5%, ${theme.palette.primary.light})`,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        flexGrow: 1
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const Main: React.FC<{ tabValue: number }> = ({ tabValue }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <TabPanel value={tabValue} index={0}>
                    <Home />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Projects />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <About />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Contact />
                </TabPanel>
            </Container>
        </div>
    );
};

export default Main;