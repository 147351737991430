import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactPlayer from "react-player";

import { Project } from '../types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    accordion: {
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
        backgroundColor: theme.palette.secondary.dark,
        borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
    },
    expanded: {

    },
    tags: {
        position: "relative",
        top: "0.4rem",
        marginRight: "2rem",
        color: theme.palette.secondary.light,
        [theme.breakpoints.down('xs')]: {
            top: "0"
        },
    },
    image: {
        "display": "block",
        "maxWidth": "95%",
        "maxHeight": "600px",
        "width": "auto",
        "height": "auto",
    },
    featList: {
        marginTop: "0",
        [theme.breakpoints.down('xs')]: {
            marginTop: "1rem",
            paddingLeft: "1.2rem"
        },
    },
    linkContainer: {
        paddingLeft: "0.6rem",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "0",
        },
    },
    link: {
        color: theme.palette.text.primary,
        paddingLeft: "0.6rem",
    }
}));


const ProjectContainer: React.FC<{ project: Project }> = ({ project }) => {
    const classes = useStyles();

    const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

    const renderText = (txt: string) => (
        txt
            .split(" ")
            .map((part: string) =>
                URL_REGEX.test(part)
                    ?
                    <a
                        href={part}
                        className={classes.link}
                        style={{ paddingLeft: 0 }}
                        target="_blank"
                        rel="noreferrer"
                        key={part}
                    >
                        {part}
                    </a>
                    : part + " "
            )
    );



    return (
        <Accordion square classes={{ root: classes.accordion, expanded: classes.expanded }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={project.id.toString()}
            >
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography
                            variant="h6" color="textPrimary"
                        >
                            {project.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={9}>
                        <Typography
                            variant="body2"
                            className={classes.tags}>
                            {`[ ${project.tags.join(", ")} ]`}
                        </Typography>
                    </Grid>

                </Grid>

            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item sm={6} xs={12}>
                        {project.image && <img src={`${process.env.PUBLIC_URL}/images/${project.image}`} alt={project.name} className={classes.image} />}
                        {project.video && <ReactPlayer className={classes.image} url={project.video} controls={true} />}
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1" className={classes.linkContainer}>
                            Features:
                        </Typography>
                        <ul className={classes.featList}>
                            {project.features.map((feat) =>
                                <li key={feat}>{renderText(feat)}</li>)}
                        </ul>
                        {project.url &&
                            <Grid container>
                                <Grid item sm={12} md={2} lg={1}>
                                    <Typography variant="body2" className={classes.linkContainer}>
                                        URL:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <a
                                            className={classes.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            href={project.url}
                                        >
                                            {project.url}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>}

                        {project.repository &&
                            <Grid container>
                                <Grid item sm={12} md={2} lg={1}>
                                    <Typography variant="body2" className={classes.linkContainer}>
                                        Git:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <a
                                            className={classes.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            href={project.repository}
                                        >
                                            {project.repository}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>}

                    </Grid>

                </Grid>

            </AccordionDetails>
        </Accordion>
    );
};

export default ProjectContainer;