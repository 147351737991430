import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ProjectsData from '../projects-data';
import ProjectContainer from './ProjectContainer';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "3rem",
        marginBottom: "1rem"
    },
    header: {
        marginBottom: "2rem",
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
    },
    text: {
        textAlign: 'justify',
        marginBottom: "1rem",
    },
    link: {
        color: theme.palette.text.primary
    }
}));

const Projects = () => {
    const styles = useStyles();
    const projects = ProjectsData.projects;

    return (
        <Container className={styles.root} maxWidth="lg" >
            <Box textAlign="center">
                <Typography variant="h4" color="textPrimary" className={styles.header}>
                    Project listing
                </Typography>
            </Box>
            {projects.map((project) =>
                <ProjectContainer key={project.id} project={project} />
            )}

        </Container>
    );
};

export default Projects;