import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
        marginTop: "3rem",
    },
    header: {
        marginBottom: "2rem",
    },
    text: {
        textAlign: 'justify',
        marginBottom: "1rem",
    },
    link: {
        color: theme.palette.text.primary
    }
}));

const About = () => {
    const styles = useStyles();
    return (
        <Container className={styles.root} maxWidth="sm" >
            <Box textAlign="center">
                <Typography variant="h4" color="textPrimary" className={styles.header}>
                    Mari Metsola
                </Typography>
                <Typography variant="body1" color="textPrimary" className={styles.text}>
                    Ever since I was a kid I have been drawn to computers, software and games.
                    I even remember exploring HTML and Visual Basic back in the 90&apos;s.
                    My programming properly took off early 2017 when I started learning the basics by completing Aalto University&apos;s MOOC.
                    After that I attended a game programming training at Amiedu for a year. In addition to some designing and coding of games with Unity and C#,
                    I learned quite a bit about working as a part of a team and even got a taste of Scrum project management.
                </Typography>
                <Typography variant="body1" color="textPrimary" className={styles.text}>
                    After getting myself a little diploma from Amiedu I decided to broaden my knowledge of coding by delving
                    into other technologies like Python and C++ to name a few. I also started studying full stack web development using common technologies
                    such as React and Node. I found and finished University of Helsinki&apos;s brilliant&thinsp;
                    <a href="https://fullstackopen.com/" target="_blank" rel="noreferrer" className={styles.link}>course</a> on the subject, gaining quite a bit of knowledge
                    and as a practical work making <a href="https://larilofman.com/kauppalappu" target="_blank" rel="noreferrer" className={styles.link}>a shopping list web application</a> that I find myself often using.
                </Typography>
                <Typography variant="body1" color="textPrimary" className={styles.text}>
                    During the summer of 2021 I participated in University of Helsinki&apos;s Software Engineering Lab <a href="https://studies.helsinki.fi/courses/course-unit/hy-CU-118025059-2021-08-01" target="_blank" rel="noreferrer" className={styles.link}>course</a>.
                    I learned a lot about working in a group, negotiating with client, having an organized Scrum project and even more about technical things such as the secrets of a Linux Server.
                </Typography>
                <Typography variant="body1" color="textPrimary" className={styles.text}>
                    In the september of 2022 I got a practical training job at <a href="https://hyvanmielenpelit.fi/" target="_blank" rel="noreferrer" className={styles.link}>Hyvän mielen pelit ry</a>.
                    I warmed up my Unity skills with some smaller games. What I found the most exciting was being able to combine my knowledge in Unity, Fullstack web developing and Linux servers when we started
                    the <a href="https://gameserver.hyvanmielenpelit.fi/virtual-world" target="_blank" rel="noreferrer" className={styles.link}>Virtual World</a> project. Of course
                    there was a lot of problems to solve and new things to learn.
                </Typography>
                <Typography variant="body1" color="textPrimary" className={styles.text}>
                    My hobbies include working out at the gym, jogging, yoga and playing computer games.
                </Typography>
            </Box>
        </Container>
    );
};

export default About;