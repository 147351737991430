import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#b0a295',
            // light: '#e4d1bf',
            main: '#938e94',
            dark: '#5d5c61',
            contrastText: '#fff',
        },
        secondary: {
            main: '#7395ae',
            dark: '#557a95',
            contrastText: '#fff',
        },
        text: {
            // primary: '#e8e8f2',
            primary: '#fff',
            secondary: '#cdd4d9'
        }
    },
    typography: {
        fontFamily: [
            'Arial',
            'Helvetica',
            'sans-serif'
        ].join(',')
    }
});

export default theme;