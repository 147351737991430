import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 2),
        background: `linear-gradient(90deg, ${theme.palette.primary.dark} 1%, ${theme.palette.secondary.dark}, ${theme.palette.primary.dark} 99%)`,
        textAlign: "center",
        color: theme.palette.text.primary
    },
}));

const Navbar: React.FC<{ tabValue: number, setTabValue: (newValue: number) => void }> = ({ tabValue, setTabValue }) => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <AppBar className={classes.root} position="sticky">
            <Tabs
                value={tabValue}
                onChange={handleChange}
                centered
                indicatorColor="secondary"
                textColor="inherit"
            >
                <Tab centerRipple label="Home" />
                <Tab centerRipple label="Projects" />
                <Tab centerRipple label="About" />
                <Tab centerRipple label="Contact" />
            </Tabs>
        </AppBar>
    );
};

export default Navbar;