import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
    root: {
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.6)",
        marginTop: "3rem"
    },
    email: {
        '&:hover': {
            color: theme.palette.primary.dark,
            textShadow: "1px 1px 1px rgba(255, 255, 255, 0.3)"
        }
    },
    iconContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "2rem",
        '& > :first-child': {
            marginRight: "1.5rem"
        },
        '& > :last-child': {
            marginLeft: "1.5rem"
        }
    },
    icon: {
        color: theme.palette.text.primary,
        '&:hover': {
            color: theme.palette.primary.dark
        }
    }
}));

const Contact = () => {
    const styles = useStyles();
    return (
        <Container className={styles.root}>
            <Box textAlign="center">
                <Typography variant="h6" color="textPrimary" className={styles.email} component="a" href="mailto:cocosweetsmail@gmail.com">
                    cocosweetsmail@gmail.com
                </Typography>
            </Box>

            <Box className={styles.iconContainer}>
                <IconButton
                    className={styles.icon}
                    component="a"
                    target="_blank"
                    href="https://github.com/marimetsola/"
                >
                    <GitHubIcon fontSize="large" />
                </IconButton>
                <IconButton
                    className={styles.icon}
                    component="a"
                    target="_blank"
                    href="https://www.linkedin.com/in/marimetsola"
                >
                    <LinkedInIcon fontSize="large" />
                </IconButton>
            </Box>
        </Container>
    );
};

export default Contact;