import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Footer from './components/Footer';
import theme from './theme';

// interface WelcomeProps {
//     name: string
// }

// const Welcome: React.FC<WelcomeProps> = ({ name }) => {
//     return <h1> Hello, {name}</h1>;
// };

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        padding: 0
    }
}));

const App = () => {
    const [tabValue, setTabValue] = React.useState(0);
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <Navbar tabValue={tabValue} setTabValue={setTabValue} />
                <Main tabValue={tabValue} />
                <Footer />
            </div>
        </ThemeProvider>
    );
};

export default App;
