export default {
    projects: [
        {
            name: "Virtual World (WIP)",
            id: 12,
            tags:
                [
                    "C#",
                    "Unity",
                    "WebGL",
                    "Multiplayer",
                    "Node",
                    "Javascript"
                ],
            url: "https://gameserver.hyvanmielenpelit.fi/virtual-world/",
            repository: "https://github.com/Pelinrakennusryhma/VirtualWorld",
            features:
                [
                    "HTTPS multiplayer WebGL game",
                    "Create account and login",
                    "3d city environment with different activities",
                    "NPCs with dialog system",
                    "Quest system",
                    "Inventory system",
                    "Node backend for storing user/character data"
                ],
            image: "virtualworld.jpg",
            video: undefined
        },
        {
            name: "Avaruuspeli",
            id: 11,
            tags:
                [
                    "C#",
                    "Unity"
                ],
            url: "https://github.com/Pelinrakennusryhma/Avaruuspeli/releases/tag/v1.0.2-beta",
            repository: "https://github.com/Pelinrakennusryhma/Avaruuspeli",
            features:
                [
                    "Traversable galaxy map with points of interest",
                    "Planets can be explored with character",
                    "Asteroid fields can be explored with spaceship",
                    "Enemy ships with behaviour tree system",
                    "Tools to gather minerals with",
                    "Shops for selling and buying items"
                ],
            image: "avaruuspeli.jpg",
            video: undefined
        },
        {
            name: "DiceMaster 2000",
            id: 10,
            tags:
                [
                    "C#",
                    "Unity",
                    "WebGL"
                ],
            url: "https://pelit.hyvanmielenpelit.fi/dicemaster2000/",
            repository: "https://github.com/marimetsola/DiceThrowing",
            features:
                [
                    "Selectable type and amount of dice",
                    "Optional bonus and penalty dice",
                    "Optional static number to be added or removed from result",
                    "Automatic zoom between thrown dice",
                    "Throw again to add to the score or clear the table",
                    "Dropdown list showing score history",
                    "QuickMenu for saving and loading dice setups",
                    "Changeable environment",
                    "Depth of field effect"
                ],
            image: "dicemaster.jpg",
            video: undefined
        },
        {
            name: "CS Discord Bot",
            id: 9,
            tags:
                [
                    "Javascript",
                    "Discord",
                    "PostgreSQL"
                ],
            url: undefined,
            repository: "https://github.com/CS-DISCORD-BOT/cs-discord-bot",
            features:
                [
                    "Helsinki University software production project",
                    "Staff can create channels for courses",
                    "Optional hidden course channels",
                    "Students can see available courses and join their channels",
                    "Slash commands"
                ],
            image: "discordbot.jpg",
            video: undefined
        },
        {
            name: "React RPG",
            id: 8,
            tags:
                [
                    "TypeScript",
                    "React"
                ],
            url: "",
            repository: "https://github.com/marimetsola/react-rpg",
            features:
                [
                    "A roguelike game",
                    "Made with functional React",
                    "Redux state with multiple slices",
                    "Random map generation",
                    "Pathfinding",
                    "Note to self: don't make a game with React"
                ],
            image: "react_rpg.jpg",
            video: undefined
        },
        {
            name: "Kauppalappu",
            id: 7,
            tags:
                [
                    "TypeScript",
                    "React",
                    "Mongoose",
                    "Express",
                    "Node"
                ],
            url: "https://marimetsola.com/kauppalappu",
            repository: "https://github.com/marimetsola/shopping-list",
            features:
                [
                    "Shopping list web app",
                    "Made with MERN stack",
                    "Desktop and mobile friendly",
                    "Create and manage lists",
                    "Invite and uninvite other users to your lists",
                    "Reset password via email link"
                ],
            image: "kauppalappu.jpg",
            video: undefined
        },
        {
            name: "Timer (web)",
            id: 6,
            tags:
                [
                    "JavaScript", "React"
                ],
            url: "https://marimetsola.github.io/timers/#/",
            repository: "https://github.com/marimetsola/timers",
            features:
                [
                    "JavaScript and React practice project",
                    "Three different timers: stopwatch, egg timer and pomodoro timer"
                ],
            image: "eggtimer.jpg",
            video: ""
        },
        {
            name: "Sheep Dog",
            id: 5,
            tags:
                [
                    "GDScript", "Godot", "WebGL"
                ],
            url: "https://marimetsola.com/sheep-dog-game",
            repository: undefined,
            features:
                [
                    "Godot and Aseprite practice project",
                    "Sheep are herded to their pen with mouse controls",
                    "Five levels with increasing difficulty"
                ],
            image: "sheep_dog.jpg",
            video: undefined
        },
        {
            name: "Timer (desktop)",
            id: 4,
            tags:
                [
                    "Python"
                ],
            url: undefined,
            repository: "https://github.com/marimetsola/eggtimer",
            features:
                [
                    "Timer mode that plays a sound and flashes application and taskbar icon on expiration",
                    "Pomodoro mode that toggles between work and break timers notifying change with sound and color",
                    "Two formats for showing time",
                    "Keeps settings saved in a json file"
                ],
            image: "eggtimer_python.gif",
            video: undefined
        },
        {
            name: "A* pathfinding",
            id: 3,
            tags:
                [
                    "Python", "Pygame"
                ],
            url: undefined,
            repository: "https://github.com/marimetsola/grid",
            features:
                [
                    "Made with Pygame",
                    "Practice project for learning Python and understanding pathfinding",
                    "Finds fastest route in a grid",
                    "Uses Manhattan method for estimating distances",
                    "Tiles with different movement costs",
                    "Option for diagonal movement",
                    "Maps can be edited with basic text editor",
                    "Simple custom menu system"
                ],
            image: "astar.gif",
            video: undefined
        },
        {
            name: "Space Race",
            id: 2,
            tags:
                [
                    "C#", "Unity"
                ],
            url: undefined,
            repository: undefined,
            features:
                [
                    "Made with Unity using C#",
                    "3D game",
                    "By a group of Amiedu's game developer students",
                    "Fully functional shop and upgrade system for cars",
                    "Somewhat smart enemy car AI"
                ],
            image: undefined,
            video: "https://www.youtube.com/watch?v=6V7uL6z2LZ8"
        },
        {
            name: "Spider Robot Invasion",
            id: 1,
            tags:
                [
                    "C#", "Unity"
                ],
            url: undefined,
            repository: undefined,
            features:
                [
                    "Made with Unity using C#",
                    "2D game with an isometric view",
                    "By a group of Amiedu's game developer students",
                    "Uses a grid for movement and collision",
                    "Three complete levels including a tutorial and an end boss",
                    "Character progress system"
                ],
            image: undefined,
            video: "https://www.youtube.com/watch?v=tfxK7G3yyC8"
        },
        {
            name: "Game of Cosmonauts",
            id: 0,
            tags:
                [
                    "C#", "Unity", "WebGL"
                ],
            url: "",
            repository: undefined,
            features:
                [
                    "Made with Unity using C#",
                    "Personal 2D WebGL project",
                    "Five levels of platform fun"
                ],
            image: "goc.jpg",
            video: undefined
        },
    ]
};