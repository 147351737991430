import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import avatar from '../images/avatar.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        textShadow: "3px 3px 2px rgba(0, 0, 0, 0.5)"
    },
    avatar: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '30vh',
        height: '30vh',
        margin: '4rem 0 3rem 0',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.palette.text.primary,
        boxShadow: "0 0 5px 3px rgba(0, 0, 0, 0.3)",
    }
}));

const Home = () => {
    const styles = useStyles();
    return (
        <>
            <Avatar alt="Mari" src={avatar} className={styles.avatar} />
            <Typography variant="h3" align="center" color="textPrimary" className={styles.root}>

                {"Hi! I'm Mari and I code."}
            </Typography >
        </>
    );
};

export default Home;